.nhlTopClubsWrapper{
    padding: 16px 0px;
}

.nhlTopClubsText h4 {
    color: var(--primary);
    font-weight: bold !important;
    border-bottom: 1px solid var(--primary);
}
.nhlTopClubsContent{
    display: flex;
    gap: 16px;
}

.nhlTopClubData{
    width: 200px;
    height: 200px;
    background-color: var(--secondary);
    border-radius: 24px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.nhlTopClubData div p{
    color: var(--fourth);
    font-weight: bold;
}