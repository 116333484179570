/* .nbaRankingWrapper {} */

.nbaRankingText h4 {
    color: var(--primary);
    font-weight: bold !important;
    border-bottom: 1px solid var(--primary);
}

.stateContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.stateSelected{
    cursor: pointer;
    background-color: var(--primary);
    color: var(--white);
    padding: 4px 24px;
    border-radius: 8px;
}
.stateNotSelected{
    padding: 4px 24px;
    cursor: pointer;
}

.nbaRankingsContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 24px;
}
.nbaRankings{
    width: 150px !important;
    height: 150px;
    background-color: var(--secondary);
    border-radius: 16px;
    position: relative;
}
.nbaRankingsRank{
    width: 150px !important;
    height: 150px;
    position: absolute;
    z-index: 1;
    font-size: 100px;
    font-weight: bold;
    color: var(--primary);
    opacity: 0.05;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nbaRankingsContentContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 2 !important;
    top: 0;
}

.nbaRankingNameContainer{
    text-align: center;
    height: 50px;
}

.nbaRankingWinLossContainer{
    color: var(--fourth);
    font-weight: bold !important;
}