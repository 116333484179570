.highlightsWrapper {
    height: 60vh;
    overflow: auto;
}

.highlightsContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
}

.highlightsContent {
    width: 100% !important;
    border-radius: 16px;

}