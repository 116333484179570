.MlsLeagueWrapper{
    padding:32px 0px
}
.MlsLeagueText h4 {
    color: var(--primary);
    font-weight: bold !important;
    border-bottom: 1px solid var(--primary);
}


.MlsLeagueDataContentFirst{
    background-color: var(--primary);
    height: 150px;
    width: 400px;
    color: var(--white) !important;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}