:root {
    --primary: #000000;
    --secondary: #00C9C8;
    --third: #BFFCF9;
    --fourth: #47817F;
    --black: #000;
    --gray: #3e3e3e;
    --white: #fff;
}

@font-face {
    font-family: Popins;
    src: url(./assets/fonts/Poppins-Regular.ttf);
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Popins';
}


body {
    overflow-x: hidden;
}

body::-webkit-scrollbar {
    display: none;
}

p {
    margin: 0;
    padding: 0;
}