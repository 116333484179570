.PremierleagueGoalsWrapper{
    padding: 32px 0px;
}

.PremierleagueGoalsText h4{
    color: var(--primary);
    font-weight: bold !important;
    border-bottom: 1px solid var(--primary);
}
.PremierleagueGoalsContent{
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 32px;
}
.PremierleagueGoalsData{
    color: var(--white);
    font-weight: bold;
    width: 400px;
    height: 210px;
    background-color: var(--primary);
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
}