.NbaDraftsText h4 {
    color: var(--primary);
    font-weight: bold !important;
    border-bottom: 1px solid var(--primary);
}

.nbaAccordionBody{
    padding: 8px 0px;
    border-bottom: 1px solid var(--third);
}

.nbaAccordionBody p {
    color: var(--secondary);
    font-weight: bold !important;
}

.accordion-item {
    border: none;
}

.accordion-item .accordion-button{
    padding: 6px;
    cursor: pointer;
    outline: none;
    color: var(--fourth);
    font-weight: bold;
}
.accordion-button:not(.collapsed){
    background-color: transparent;
    outline: none;
}
.accordion-button:focus {
    box-shadow: none !important;
    outline: none !important;
}
.accordion-button:not(.collapsed)::after{
    background-image: url(../../assets/images/minus.svg);
}

.accordion-button::after{
    background-image: url(../../assets/images/plus.svg);
    width: 14px;
    height: 14px;
    background-size:14px;
}