.testimonialWrapper {
    margin-top: 32px;
    padding: 16px;
    display: flex;
    width: 100%;
    /* align-items: center; */
    /* justify-content: center; */
    flex-direction: column;
    background-color: var(--third);
}
.testimonialTitleContainer{
    border-bottom: 1px solid var(--primary);
}

.testimonialWrapper .reviewSwiper {
    padding: 24px 0px;
    color: var(--white);
}

.testimonialWrapper .reviewSwiper .ratingNameContainer {
    margin-left: 16px;
}

.testimonialWrapper .reviewSwiper .testimonialBodyContainer {
    height: 200px;
    padding-top: 24px;
    text-align: left !important;
}

.testimonialWrapper .reviewSwiper .swiper {
    width: 100% !important;
}

.testimonialWrapper .reviewSwiper .swiper-slide {
    width: 320px !important;
    background-color: var(--secondary);
    padding: 24px;
    height: 320px !important;
}

.testimonialsContainer::-webkit-scrollbar {
    display: none !important;
}
